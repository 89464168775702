import React from 'react'

const Skills = () => {
  return (
    <div className='skills-container'>
        Skills
    </div>
  )
}

export default Skills