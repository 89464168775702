import React from 'react'
import './projects.css'

const Projects = () => {
  return (
    <div className='projects-container'>
        Projects
    </div>
  )
}

export default Projects